<template>
  <!-- 视图区域 -->
  <div>
    <router-view v-slot="{ Component, route }">
      <keep-alive>
        <component
          :is="Component"
          v-if="!route.meta.keepAlive"
          :key="route.fullPath"
        />
      </keep-alive>
      <component
        :is="Component"
        v-if="route.meta.keepAlive"
        :key="route.fullPath"
      />
    </router-view>
    <!-- <router-view /> -->
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const keepAlive = computed(() => {
  return route.matched.some((record) => record.meta.keepAlive)
})
</script>
<style lang="scss" scoped></style>
